<template>
<!-- 讨论区 -->
  <div style="background: #f4f4f4">
    <div class="discussion">
      <el-row :gutter="20">
        <el-col :span="18">
          <div class="discussArea">
            <el-row
              style="
                background: #fff;
                margin-bottom: 12px;
                padding: 20px;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
                position: relative;
              "
            >
              <div
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  text-align: center;
                  line-height: 90px;
                  z-index: 99999;
                  background: rgba(0, 0, 0, 0.2);
                  color: #fff;
                "
                v-if="!userInfo || !userInfo.id"
              >
                请先
                <router-link to="/login">
                  <el-button type="primary" size="mini" style="margin: 8px"
                    >登录</el-button
                  ></router-link
                >后发表讨论
              </div>
              <el-col :span="2" style="text-align: right">
                <img
                  v-if="userInfo && userInfo.avatar"
                  :src="userInfo && userInfo.avatar"
                  alt=""
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 12px;
                  "
                />
                <img
                  class="userInfo avatar"
                  v-else
                  src="@/assets/company/userLogo.png"
                  alt=""
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 12px;
                  "
                />
              </el-col>
              <el-col :span="22">
                <el-input
                  v-if="!trendsFocus"
                  placeholder="写下你的动态..."
                  @focus="focusTrends"
                ></el-input>
                <el-input
                  v-if="trendsFocus"
                  ref="trendsTextarea"
                  type="textarea"
                  v-model="comment"
                  rows="4"
                  placeholder="写下你的动态..."
                ></el-input>
                <el-row style="min-height: 10px">
                  <el-upload
                    multiple
                    :action="uploadUrl"
                    ref="upload"
                    list-type="picture-card"
                    :on-success="handleCommentPicSuccess"
                    :before-upload="beforeAvatarUpload"
                    :limit="9"
                    accept=".jpg, .jpeg, .png, .gif"
                    :data="{ needCut: true, isPublic: true }"
                    :headers="{ 'X-Access-Token': token }"
                    :on-remove="removeFile"
                  >
                    <i class="el-icon-plus" ref="uploadComPic"></i>
                  </el-upload>
                </el-row>
                <div v-if="trendsFocus" class="functionalComments">
                  <div>
                    <emotions @chooseEmotion="handleEmotions"></emotions>
                    <el-button style="padding: 4px" @click="uploadPics">
                      <i class="el-icon-picture"></i>
                    </el-button>
                    <el-button
                      type="primary"
                      style="float: right; padding: 4px"
                      @click="publishDiscussion"
                    >
                      发布
                    </el-button>
                    <el-checkbox style="float: right" v-model="anonymity"
                      >匿名发布</el-checkbox
                    >
                  </div>
                </div>
              </el-col>
            </el-row>

            <div class="trendsList">
              <div
                class="trendsComment"
                v-for="(trend, index) in trendsList"
                :key="index"
              >
                <div>
                  <el-row :gutter="16">
                    <el-col :span="2" style="text-align: right">
                      <router-link
                        v-if="trend.avatar"
                        :to="`/userPage?userId=${trend.userId}`"
                      >
                        <img
                          :src="trend.avatar"
                          class="userAvatar"
                          alt=""
                          @click.stop="jumpUserPage(trend.userId)"
                        />
                      </router-link>

                      <div v-else class="anonymous">匿名</div>
                    </el-col>
                    <el-col :span="22">
                      <div class="username">
                        {{ trend.username ? trend.username : "匿名用户" }}
                        <span class="createTime">{{ trend.createTime }}</span>
                        <span
                          class="trendType orange"
                          v-if="trend.content.companyName"
                          >{{ trend.content.companyName }}</span
                        >
                      </div>
                      <div class="trends-content">
                        {{ trend.content.content }}
                      </div>
                      <div class="trends-pictures">
                        <el-image
                          v-for="(image, index) in trend.content.pictures"
                          style="
                            width: 100px;
                            height: 100px;
                            margin-right: 12px;
                          "
                          :src="image"
                          :key="index"
                          @click="
                            picArray = trend.content.pictures;
                            showIndex = index;
                            showViewer = true;
                          "
                        />
                      </div>
                      <div class="trends-operator">
                        <div
                          class="likeNums"
                          @click="publishChildComment(trend, false)"
                        >
                          <i class="iconfont icon-dianzan"></i
                          >{{ trend.likeNums }}
                        </div>
                        <div
                          class="commentNums"
                          @click="openParentReply(trend)"
                        >
                          回复
                        </div>
                      </div>
                      <div class="replyContainer">
                        <el-input
                          v-if="trend.reply"
                          v-model="trend.replyText"
                          @input="$forceUpdate()"
                          :placeholder="`评论${
                            trend.username ? trend.username : '匿名用户'
                          }...`"
                        >
                          <template slot="append">
                            <emotions
                              @chooseEmotion="
                                (emotion) =>
                                  handleChildCommentEmotion(emotion, trend)
                              "
                            ></emotions>
                            <el-button
                              style="
                                margin-left: 4px;
                                border-left: 1px solid #dcdfe6;
                                border-radius: 0px;
                              "
                              @click="publishChildComment(trend, true)"
                              >评论</el-button
                            >
                          </template>
                        </el-input>

                        <div class="replyList" v-if="trend.content.children">
                          <div
                            class="replies"
                            v-for="(reply, rIndex) in trend.content.showAll ||
                            trend.content.total <= 2
                              ? trend.content.children
                              : trend.content.children.slice(0, 2)"
                            :key="rIndex"
                          >
                            <router-link
                              v-if="reply.avatar"
                              :to="`/userPage?userId=${reply.userId}`"
                            >
                              <img :src="reply.avatar" class="replyUserAvatar"
                            /></router-link>

                            <div
                              v-if="!reply.toAvatar || !reply.toUsername"
                              class="reply-area"
                            >
                              {{ reply.username }}:
                              <span class="commentContent">
                                {{
                                  reply.visibility
                                    ? reply.content
                                    : "该评论已删除"
                                }}
                              </span>
                            </div>
                            <div v-else class="reply-area">
                              {{ reply.username }}
                              <i class="el-icon-caret-right"></i>
                              {{ reply.toUsername }}:
                              <span class="commentContent">
                                {{
                                  reply.visibility
                                    ? reply.content
                                    : "该评论已删除"
                                }}
                              </span>
                            </div>
                            <div class="reply-operator">
                              <span class="reply-time">{{
                                reply.createTime
                              }}</span>
                              <span class="reply-likeNum">
                                <i
                                  class="iconfont icon-dianzan"
                                  @click="
                                    publishSecondChildComment(
                                      trend,
                                      reply,
                                      false
                                    )
                                  "
                                ></i>
                                {{ reply.likeNums }}</span
                              >
                              <span
                                class="reply-reply"
                                @click="openChildReply(reply)"
                                >回复</span
                              >
                            </div>
                            <div
                              class="replys"
                              v-if="reply.reply"
                              style="margin-top: 8px"
                            >
                              <el-input
                                v-model="reply.replyText"
                                :placeholder="`回复${reply.username}...`"
                                @input="$forceUpdate()"
                              >
                                <template slot="append">
                                  <emotions
                                    @chooseEmotion="
                                      (emotion) =>
                                        handleChildCommentEmotion(
                                          emotion,
                                          reply
                                        )
                                    "
                                  ></emotions>
                                  <el-button
                                    style="
                                      margin-left: 4px;
                                      border-left: 1px solid #dcdfe6;
                                      border-radius: 0px;
                                    "
                                    @click="
                                      publishSecondChildComment(
                                        trend,
                                        reply,
                                        true
                                      )
                                    "
                                    >回复</el-button
                                  >
                                </template>
                              </el-input>
                            </div>
                          </div>
                          <div
                            style="text-align: left; padding: 12px 0"
                            v-if="
                              trend.content.total > 10 && trend.content.showAll
                            "
                          >
                            <el-pagination
                              style="padding: 0"
                              prev-text="上一页"
                              next-text="下一页"
                              :current-page.sync="trend.content.current"
                              :total="trend.content.total"
                              layout="prev, pager, next"
                              @current-change="
                                (page) => getTrendsCommentList(trend, page)
                              "
                            >
                            </el-pagination>
                          </div>
                          <div
                            v-if="
                              trend.content.total > 2 && !trend.content.showAll
                            "
                          >
                            共{{ trend.content.total }}条回复，<span
                              style="color: #00a1d6; cursor: pointer"
                              @click="
                                trend.content.showAll = true;
                                $forceUpdate();
                              "
                              >点击查看</span
                            >
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="no-trends" v-if="trendsList.length == 0">
                暂无动态！
              </div>
            </div>
            <el-pagination
              style="padding: 20px; text-align: center"
              :current-page.sync="currentPage"
              :total="total"
              layout="prev, pager, next"
              @current-change="getDiscussion"
            >
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="6">
          <SideAdvertisement></SideAdvertisement>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import emotions from "@/utils/emotions/index";
import { publishDiscussion, getDiscussion } from "@/service/leetcode";
import {
  getTrendsComments,
  publishComment,
  getTrendDetail,
} from "@/service/comments";
import SideAdvertisement from "@/components/sideAdvertisement";
export default {
  name: "trendsPublish",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  components: { emotions, SideAdvertisement },
  data() {
    return {
      trendsFocus: false,
      comment: "",
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      commentsPic: [],
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      suggestionType: 0,
      anonymity: false,
      trendsList: [],
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    focusTrends() {
      this.trendsFocus = true;
      this.$nextTick(() => {
        this.$refs.trendsTextarea.focus();
      });
    },
    handleEmotions(emotion) {
      this.comment = this.comment + emotion;
    },
    uploadPics() {
      this.$refs.uploadComPic.click();
    },
    handleCommentPicSuccess(res) {
      this.commentsPic.push(res.result.objectKey);
    },
    beforeAvatarUpload(file) {
      console.log(file);
      if (this.commentsPic.length >= 9) {
        return this.$message.error("最多只能上传9张图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    removeFile(file) {
      let removeIndex = this.commentsPic.indexOf(
        file.response.result.accessibleUrl
      );
      this.commentsPic.splice(removeIndex, 1);
      console.log(this.commentsPic);
    },
    publishDiscussion() {
      if (this.comment == "") {
        return this.$message.error("发布内容不能为空！");
      }
      const data = {
        content: this.comment,
        pictures: this.commentsPic,
        anonymity: this.anonymity,
      };
      publishDiscussion(data).then((res) => {
        if (res.success) {
          this.comment = "";
          this.commentsPic = [];
          this.anonymity = false;
          this.trendsFocus = false;
          this.$refs.upload.clearFiles();
          this.$message.success("发布成功!");
          this.getDiscussion();
        }
      });
    },
    jumpUserPage(userId) {
      this.$router.push(`/userPage?userId=${userId}`);
    },
    openParentReply(item) {
      item.reply = item.reply ? !item.reply : true;
      this.$forceUpdate();
    },
    openChildReply(item) {
      item.reply = item.reply ? !item.reply : true;
      this.$forceUpdate();
    },
    handleChildCommentEmotion(emotion, comment) {
      if (comment.replyText) {
        comment.replyText = comment.replyText + emotion;
      } else {
        comment.replyText = "" + emotion;
      }
      this.$forceUpdate();
    },
    publishChildComment(comment, isComment) {
      if (isComment && (!comment.replyText || comment.replyText == "")) {
        return this.$message.error("请输入评论内容！");
      }
      console.log(comment);
      const data = {
        content: isComment ? comment.replyText : "",
        superType: 10,
        type: isComment,
        superId: comment.content.id,
        parentId: 0,
        commentId: 0,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.success(`${isComment ? "评论" : "点赞"}成功！`);
          this.updateTrend(comment);
          this.updateTrendDetail(comment);
          comment.replyText = "";
          this.$forceUpdate();
        } else {
          this.$message.info(res.message);
        }
      });
    },
    publishSecondChildComment(parentComment, childComment, isComment) {
      if (
        isComment &&
        (!childComment.replyText || childComment.replyText == "")
      ) {
        return this.$message.error("请输入内容！");
      }
      const data = {
        content: isComment ? childComment.replyText : "",
        superType: 10,
        type: isComment,
        superId: parentComment.content.id,
        parentId: childComment.id,
        commentId: childComment.id,
      };
      publishComment(data).then(async (res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.updateTrend(parentComment);
          this.updateTrendDetail(parentComment);
          this.$forceUpdate();
        } else {
          this.$message.info(res.message);
        }
      });
    },
    updateTrend(trend) {
      trend.content.current = trend.content.current ? trend.content.current : 1;
      getTrendsComments(trend.content.id, trend.content.current, 10).then(
        (res) => {
          if (res.success) {
            trend.content.children = res.result.records;
            trend.content.total = res.result.total;
            trend.content.showAll = true;
            this.$forceUpdate();
          }
        }
      );
    },

    updateTrendDetail(trend) {
      getTrendDetail(trend.content.id, 10).then((res) => {
        if (res.success) {
          console.log(res);
          trend.likeNums = res.result.likeNums;
          trend.commentNums = res.result.commentNums;
        }
      });
    },
    getTrendsCommentList(trend, page) {
      trend.content.current = page;

      getTrendsComments(trend.content.id, trend.content.current, 10).then(
        (res) => {
          if (res.success) {
            trend.content.children = res.result.records;
            trend.content.total = res.result.total;
            this.$forceUpdate();
          }
        }
      );
    },

    getDiscussion(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getDiscussion({ current: this.currentPage }).then((res) => {
        if (res.success) {
          this.total = res.result.total;
          this.trendsList = res.result.records;
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.getDiscussion();
  },
};
</script>
<style scoped lang="scss">
.discussion {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}
::v-deep .el-upload.el-upload--picture-card {
  display: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  margin-top: 8px;
}
.el-radio.is-bordered {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    padding-left: 0;
  }
}
::v-deep .el-radio--small.is-bordered {
  padding: 8px 10px;
  border-radius: 16px;
}
::v-deep .el-radio {
  margin-right: 0px;
}
.discussArea {
  padding: 20px;
  padding-top: 0px;
}

.trendsList {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 20px;
  .trendsComment {
    padding: 20px 0;
    cursor: pointer;
  }
  .trendsComment:not(:last-child) {
    border-bottom: 1px solid #e4e4e4;
  }
  .userAvatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
  }
  .anonymous {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
    background: rgba(10, 122, 255, 1);
    text-align: center;
  }
  .username {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
  }
  .reply {
    float: right;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 21px;
    cursor: pointer;
  }
  .replyContainer {
    margin-top: 8px;
    .replyList {
      margin-top: 8px;
      .replies {
        padding: 8px 0;
        .replyUserAvatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 6px;
        }
        .reply-area {
          display: inline-block;
          width: calc(100% - 40px);
          vertical-align: top;
          line-height: 24px;

          .commentContent {
            color: #222;
            word-break: break-all;
          }
        }
        .reply-operator {
          .reply-time {
            color: #34495e;
            margin-right: 24px;
          }
          .reply-likeNum {
            color: rgba(52, 73, 94, 0.7);
            cursor: pointer;
            margin-right: 24px;
          }
          .reply-reply {
            color: rgba(52, 73, 94, 0.7);
            cursor: pointer;
          }
        }
      }
    }
  }
  .createTime {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 21px;
    margin-left: 12px;
  }
  .trendType {
    width: 50px;
    padding: 2px 12px;
    background: rgba(0, 117, 246, 0.2);
    border-radius: 9px;
    line-height: 16px;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    line-height: 18px;
    margin-left: 18px;
    &.orange {
      color: #fa6400 !important;
      background: rgba(250, 100, 0, 0.2);
    }
  }
  .orange {
    color: #fa6400 !important;
  }

  .blue {
    color: #2772db !important;
  }

  .green {
    color: #2ec1ac !important;
  }

  .red {
    color: #e02020 !important;
  }
  .sgreen {
    color: #2fbb1e !important;
  }
  .trendLabels {
    font-size: 12px;
    margin-top: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .trendTitle {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
    .title-text {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 18px;
      max-width: calc(100% - 60px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .questionNum {
      background: rgba(10, 122, 255, 0.1);
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #0a7aff;
      line-height: 18px;
      padding: 2px 6px;
      margin-left: 8px;
    }
  }
  .companyName {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #fa6400;
    line-height: 18px;
    display: inline-block;
    margin-right: 10px;
  }
  .isExplain {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #34c759;
    line-height: 18px;
    background: rgba(52, 199, 89, 0.1);
    padding: 3px 6px;
    display: inline-block;
    &.active {
      color: #e02020;
      background: rgba(224, 32, 32, 0.1);
    }
  }
  .question-title {
    margin-top: 8px;
    background: rgba(136, 148, 171, 0.1);
    border-radius: 2px;
    padding: 6px 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #292b2d;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .trends-content {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #828282;
    line-height: 20px;
    margin-top: 8px;
    word-break: break-word;
    &.ellipsis {
      display: -webkit-box;
      -webkit-line-clamp: 2; /*让他显示两行*/
      -webkit-box-orient: vertical;
      word-break: break-all; /* 允许在单词内换行。*/
      overflow: hidden;
    }
  }
  .trends-pictures {
    margin-top: 9px;
  }
  .trends-operator {
    margin-top: 12px;
    color: #34495e !important;
    .iconfont {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #828282;
      line-height: 16px;
    }
    .likeNums {
      display: inline-block;
      margin-right: 20px;
      color: #828282 !important;
      cursor: pointer;
      i {
        margin-right: 2px;
      }
    }
    .commentNums {
      display: inline-block;
      color: #828282 !important;
      cursor: pointer;
      i {
        margin-right: 4px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .no-trends {
    text-align: center;
    font-size: 20px;
    padding: 20px;
  }
}
</style>